<template>
  <div>
    <v-dialog
      scrollable
      persistent
      v-model="show"
      max-width="665px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      content-class="rounded-xl"
    >
      <v-overlay class="justify-center align-center" v-if="loading">
        <v-progress-circular
          :size="100"
          :width="8"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>

      <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="d-flex justify-space-between">
          <div>
            <div class="title-name" v-if="type == 'add'">Add Lead</div>
            <div class="title-name" v-if="type == 'edit'">Edit Lead</div>
            <div class="sub-title py-1">Please enter details here</div>
          </div>
          <v-btn icon @click="modalClosed">
            <v-icon size="26">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-form class="pt-6" lazy-validation ref="form">
            <v-text-field
              v-model="form.name"
              :rules="[$rules.required]"
              label="Name"
              type="text"
              class="rounded-lg"
              height="42px"
              outlined
              dense
            ></v-text-field>

            <div class="d-flex justify-space-between">
              <div style="width: 15%">
                <v-select
                  v-model="selectedCountry"
                  :items="country_list"
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  @input="validate_number"
                  item-text="name"
                  class="rounded-lg"
                  height="42px"
                  return-object
                  auto-select-first
                  hide-details
                  outlined
                  dense
                >
                  <template v-slot:selection="data">
                    <span class="mr-2">
                      <v-img
                        :src="data.item.flag"
                        height="20"
                        width="25"
                        class="rounded"
                        v-if="data.item.flag"
                      ></v-img>
                      <div v-else>
                        {{ data.item.iso2 }}
                      </div>
                    </span>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title class="d-flex align-center">
                        <v-img
                          :src="data.item.flag"
                          height="20"
                          max-width="25"
                          class="rounded mr-2"
                          v-if="data.item.flag"
                        ></v-img>
                        <div v-else class="mr-2">
                          {{ data.item.iso2 }}
                        </div>
                        {{ data.item.name }}
                        ({{ data.item.phone_code }})
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
              <div style="width: 83%">
                <v-text-field
                  v-model="form.mob_no"
                  :rules="[$rules.required, isMobileValid]"
                  :prefix="selectedCountry ? selectedCountry.phone_code : ''"
                  @input="validate_number"
                  @keydown="$rules.blockInvalidChar"
                  label="Mobile Number"
                  type="number"
                  class="rounded-lg"
                  height="42px"
                  hide-spin-buttons
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </div>

            <v-text-field
              v-model="form.email"
              :rules="[$rules.email]"
              label="Email"
              type="email"
              class="rounded-lg"
              height="42px"
              outlined
              dense
            ></v-text-field>

            <v-text-field
              v-model="form.alternative_email"
              :rules="[$rules.email]"
              type="email"
              label="Alternate Email"
              class="rounded-lg"
              height="42px"
              outlined
              dense
            ></v-text-field>

            <v-autocomplete
              v-model="form.state"
              :items="state_list"
              :search-input.sync="state_search"
              :loading="state_list_loading"
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
              item-text="name"
              item-value="id"
              label="Select State"
              append-icon="mdi-chevron-down"
              class="rounded-lg"
              height="42px"
              auto-select-first
              outlined
              no-filter
              dense
              flat
              return-object
            >
            </v-autocomplete>

            <v-autocomplete
              v-model="form.city"
              :items="city_list"
              :search-input.sync="city_search"
              :loading="city_list_loading"
              :disabled="form.state == null"
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
              item-text="name"
              item-value="id"
              label="Select City"
              append-icon="mdi-chevron-down"
              class="rounded-lg"
              height="42px"
              auto-select-first
              outlined
              no-filter
              dense
              flat
              return-object
            >
            </v-autocomplete>

            <v-textarea
              v-model="form.address"
              type="text"
              label="Address"
              rows="2"
              class="rounded-lg"
              outlined
              dense
              flat
            ></v-textarea>

            <v-select
              v-model="form.source"
              :items="source_list"
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
              label="Source"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              class="rounded-lg"
              height="42px"
              outlined
              clearable
              dense
              flat
            ></v-select>

            <v-textarea
              v-model="form.remarks"
              label="Type remarks here..."
              class="rounded-lg"
              outlined
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="submit"
            :loading="btnLoading"
            class="text-capitalize px-8 my-2 rounded-lg"
            height="40px"
            color="primary"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "addLeadsModal",
  data() {
    return {
      loading: true,
      btnLoading: false,
      number: null,
      form: {
        name: "",
        mob_no: "",
        email: "",
        alternative_email: "",
        state: null,
        source: null,
        city: null,
        address: "",
        remarks: "",
      },
      mobile_country_code: "",
      selectedCountry: "",

      state_list: [],
      state_search: "",
      state_list_loading: false,

      city_list: [],
      city_search: "",
      city_list_loading: false,

      source_list: [],
      isMobileValid: true,
    };
  },
  computed: {
    ...mapGetters({
      getAddLeadsModal: "clientManagement/getAddLeadsModal",
      country_list: "getCountryList",
    }),

    show: {
      get() {
        return this.getAddLeadsModal;
      },
      set() {},
    },

    type() {
      return this.$store.state.clientManagement.addLeadsModal.type;
    },

    client_id() {
      return this.$store.state.clientManagement.addLeadsModal.id;
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },

    "form.state"() {
      this.form.city = null;
    },

    state_search: _.debounce(function (query) {
      if (this.state_list_loading) return;

      if (query && !this.form.state) {
        this.getStateList();
      }
    }, 1000),

    city_search: _.debounce(function (query) {
      if (this.city_list_loading) return;

      if (query && !this.form.city) {
        this.getCityList();
      }
    }, 1000),
  },
  methods: {
    ...mapActions({
      LeadsModal: "clientManagement/LeadsModal",
      showSnackbar: "app/showSnackbar",
    }),

    validate_number() {
      if (this.form.mob_no && this.selectedCountry)
        this.isMobileValid = this.$rules.phone_number_validator(
          this.form.mob_no,
          this.selectedCountry.iso2
        );
    },

    async openModal() {
      this.loading = true;

      if (this.type === "edit") {
        await this.getClientDetail();

        if (this.mobile_country_code) {
          this.selectedCountry = _.find(this.country_list, [
            "iso2",
            this.mobile_country_code,
          ]);
        } else {
          this.selectedCountry = _.find(this.country_list, ["iso2", "IN"]);
        }
      } else {
        this.selectedCountry = _.find(this.country_list, ["iso2", "IN"]);
      }

      await this.getSourceList();

      this.loading = false;
    },

    modalClosed() {
      this.LeadsModal({ show: false });
      this.$refs.form.reset();
      this.form.name = "";
      this.form.mob_no = "";
      this.form.email = "";
      this.form.alternative_email = "";
      this.form.state = null;
      this.form.source = null;
      this.form.city = null;
      this.address = "";
      this.form.remarks = "";
      this.mobile_country_code = "";
    },

    getCityList() {
      this.city_list_loading = true;

      const params = {
        apply_pagination: false,
        search: this.city_search,
        state_id: this.form.state ? this.form.state.id : null,
      };

      const successHandler = (res) => {
        this.city_list = res.data.data;
      };

      const onFinally = () => {
        this.city_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.master.city.list, {
        params: params,
        onSuccess: successHandler,
        onFinally,
      });
    },

    getStateList() {
      this.state_list_loading = true;

      const params = {
        apply_pagination: false,
        country_id: this.selectedCountry.id,
        search: this.state_search,
      };

      const successHandler = (res) => {
        this.state_list = res.data.data;
      };

      const onFinally = () => {
        this.state_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.master.state.list, {
        params: params,
        onSuccess: successHandler,
        onFinally,
      });
    },

    getSourceList() {
      const successHandler = (res) => {
        this.source_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.master.source.list, {
        onSuccess: successHandler,
      });
    },

    getClientDetail() {
      const params = {
        client_id: this.client_id,
      };

      const successHandler = (res) => {
        const data = res.data.data;

        this.form.name = data.name;

        const user = data.user;
        this.form.mob_no = user.mobile;
        this.form.email = user.email;
        this.form.alternative_email = user.alternative_email;

        if (user.mobile_country_code)
          this.mobile_country_code = user.mobile_country_code;
        else this.mobile_country_code = "IN";

        if (data.source) {
          this.form.source = data.source.id;
        }

        if (data.city) {
          this.form.city = data.city;
          this.city_list.push(data.city);
        }

        if (data.state) {
          this.form.state = data.state;
          this.state_list.push(data.state);
        }

        this.form.remarks = data.remarks;
        this.form.address = data.address;
      };

      return this.$request(this.$keys.GET, this.$urls.client.detail, {
        params: params,
        onSuccess: successHandler,
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        const successHandler = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.$emit("submitted", res.data.data.id);
          this.modalClosed();
        };

        const finallyHandler = () => {
          this.btnLoading = false;
        };

        const data = {};
        data["name"] = this.form.name;

        data["email"] = this.form.email;
        data["alternative_email"] = this.form.alternative_email;

        if (this.form.mob_no) {
          const phone = this.$utils.getPhoneNumberInfo(
            this.form.mob_no,
            this.selectedCountry.iso2
          );
          data["mobile"] = phone.phone_number;
          data["mobile_code"] = phone.phone_code;
          data["mobile_country_code"] = phone.country_code;
        } else data["mobile"] = this.form.mob_no;

        if (this.form.city) data["city"] = this.form.city.id;
        if (this.form.state) data["state"] = this.form.state.id;

        data["source"] = this.form.source;
        data["address"] = this.form.address;
        data["remarks"] = this.form.remarks;

        if (this.client_id) {
          const params = {
            client_id: this.client_id,
          };

          return this.$request(this.$keys.PUT, this.$urls.client.update, {
            params: params,
            data: data,
            onSuccess: successHandler,
            onFinally: finallyHandler,
          });
        } else {
          return this.$request(this.$keys.POST, this.$urls.client.create, {
            data: data,
            onSuccess: successHandler,
            onFinally: finallyHandler,
          });
        }
      } else {
        this.$nextTick(() => {
          const el = document.getElementsByClassName("v-input error--text");
          if (el)
            el[0].scrollIntoView({
              block: "end",
              behavior: "smooth",
            });
          return;
        });
      }
    },
  },
};
</script>
<style scoped>
.title-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
.sub-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  opacity: 0.6;
}
.v-input__prepend-inner {
  max-height: 1px !important;
  padding: 0px !important;
}
</style>
